import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "sticker" }
const _hoisted_2 = { class: "sticker__block" }
const _hoisted_3 = { class: "sticker__wrap" }
const _hoisted_4 = { class: "sticker__menu" }
const _hoisted_5 = {
  key: 0,
  class: "sticker__tempalate"
}
const _hoisted_6 = { class: "sticker__cards-wrap" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "sticker__cards-text" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "table__selects" }
const _hoisted_12 = { class: "table__head" }
const _hoisted_13 = { class: "table__head-id" }
const _hoisted_14 = { class: "table__sort-block" }
const _hoisted_15 = { class: "table__head-little-tr" }
const _hoisted_16 = { class: "table__sort-block" }
const _hoisted_17 = { class: "table__head-little-tr" }
const _hoisted_18 = { class: "table__sort-block" }
const _hoisted_19 = { class: "table__head-little-tr" }
const _hoisted_20 = { class: "table__sort-block" }
const _hoisted_21 = { class: "table__head-big-tr" }
const _hoisted_22 = { class: "table__sort-block" }
const _hoisted_23 = { class: "table__head-little-tr" }
const _hoisted_24 = { class: "table__sort-block" }
const _hoisted_25 = { class: "table__head-little-tr" }
const _hoisted_26 = { class: "table__sort-block" }
const _hoisted_27 = { class: "table__head-little-tr" }
const _hoisted_28 = { class: "table__sort-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Pluse = _resolveComponent("Pluse")!
  const _component_MotivationCard = _resolveComponent("MotivationCard")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["sticker__menu-item", { 'active-menu': _ctx.motivationalCardsTemplate }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTemplate('motivationalCards')))
        }, " Motivational cards ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["sticker__menu-item", { 'active-menu': _ctx.campaignsHistoryTemplate }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTemplate('campaignsHistory')))
        }, " Campaigns history ", 2)
      ]),
      (_ctx.motivationalCardsTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "sticker__add-new",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (this.createMotivationalCards()))
            }, [
              _createVNode(_component_Pluse),
              _cache[19] || (_cache[19] = _createTextVNode(" Add new "))
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.motivationsData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [
                  _createElementVNode("div", {
                    class: "sticker__cards-container",
                    onClick: ($event: any) => (_ctx.goToDetailCard(item.id))
                  }, [
                    (item.image_url)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "sticker__image",
                          src: item.image_url,
                          alt: "Motivational cards"
                        }, null, 8, _hoisted_8))
                      : (_openBlock(), _createBlock(_component_MotivationCard, {
                          key: 1,
                          class: "sticker__image"
                        })),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.quote), 1),
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "sticker__cards-button" }, "Send", -1))
                  ], 8, _hoisted_7)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.campaignsHistoryTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_DateRangePicker, {
                "select-date-fill": _ctx.selectDateFill,
                onOnSelectStaticRange: _ctx.selectStaticRange,
                onCloseCalendar: _ctx.closeCalendar
              }, null, 8, ["select-date-fill", "onOnSelectStaticRange", "onCloseCalendar"])
            ]),
            _cache[30] || (_cache[30] = _createStaticVNode("<div class=\"sticker__container\" data-v-0884b075><div class=\"sticker__block-analitics\" data-v-0884b075><div data-v-0884b075>Delivered</div><div class=\"sticker__block-analitics-value\" data-v-0884b075>22</div></div><div class=\"sticker__block-analitics\" data-v-0884b075><div data-v-0884b075>Viewed</div><div class=\"sticker__block-analitics-value\" data-v-0884b075>22</div></div><div class=\"sticker__block-analitics\" data-v-0884b075><div data-v-0884b075>Clicked</div><div class=\"sticker__block-analitics-value\" data-v-0884b075>22</div></div></div>", 1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[21] || (_cache[21] = _createTextVNode(" ID ")),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (this.getAnalytics('?search=', 'id')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getAnalytics('?search=', '-id'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[22] || (_cache[22] = _createTextVNode(" Picture ")),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getAnalytics('?search=', 'picture')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getAnalytics('?search=', '-picture'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[23] || (_cache[23] = _createTextVNode(" Date ")),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getAnalytics('?search=', 'date')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getAnalytics('?search=', '-date'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[24] || (_cache[24] = _createTextVNode(" Title ")),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getAnalytics('?search=', 'title')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getAnalytics('?search=', '-title'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _cache[25] || (_cache[25] = _createTextVNode(" Description ")),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getAnalytics('?search=', 'description')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getAnalytics('?search=', '-description'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _cache[26] || (_cache[26] = _createTextVNode(" Delivered ")),
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getAnalytics('?search=', 'delivered')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getAnalytics('?search=', '-delivered'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _cache[27] || (_cache[27] = _createTextVNode(" Viewed ")),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getAnalytics('?search=', 'viewed')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getAnalytics('?search=', '-viewed'))),
                    class: "table__sort-bottom"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _cache[28] || (_cache[28] = _createTextVNode(" Clicked ")),
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (this.getAnalytics('?search=', 'clicked')))
                  }),
                  _createVNode(_component_SortArrow, {
                    onClick: _cache[18] || (_cache[18] = ($event: any) => (this.getAnalytics('?search=', '-clicked'))),
                    class: "table__sort-bottom"
                  })
                ])
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.motivationsData.length, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "table__body",
                key: item
              }, [
                _createTextVNode(_toDisplayString(item) + " ", 1),
                _cache[29] || (_cache[29] = _createStaticVNode("<div class=\"table__body-id\" data-v-0884b075>22</div><div class=\"table__body-little-tr\" data-v-0884b075>img</div><div class=\"table__body-little-tr\" data-v-0884b075>22</div><div class=\"table__body-little-tr\" data-v-0884b075>22</div><div class=\"table__body-big-tr\" data-v-0884b075>22</div><div class=\"table__body-little\" data-v-0884b075>22</div><div class=\"table__body-little\" data-v-0884b075>22</div><div class=\"table__body-little\" data-v-0884b075>22</div>", 8))
              ]))
            }), 128)),
            _createVNode(_component_Pagination, {
              propertyMeta: _ctx.campaignsHistoryMeta,
              propertyList: _ctx.campaignsHistoryData,
              page: _ctx.page,
              handlePage: _ctx.handlePage
            }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}