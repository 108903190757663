
import { defineComponent } from "vue";

// molecules
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import Pagination from "@/components/molecules/Pagination.vue";

//svg
import Pluse from "@/assets/svg/pluse.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import MotivationCard from "@/assets/svg/motivationCard.svg?inline";

export default defineComponent({
  name: "MotivationalCards",
  data() {
    return {
      motivationsData: [],
      motivationsCompainData: [],
      motivationalCardsTemplate: true,
      campaignsHistoryTemplate: false,
      page: 1,
      campaignsHistoryData: null,
      campaignsHistoryMeta: null,
    };
  },
  components: {
    //moleciles
    AdminPanelNavMenu,
    DateRangePicker,
    Pagination,

    //svg
    Pluse,
    SelectArrow,
    SortArrow,
    MotivationCard
  },
  mounted() {
    this.getMotivationsCard()
    this.getMotivationCardsCampaigns()
  },
  methods: {
    getMotivationsCard() {
      this.$store.dispatch("motivations/getMotivationsList").then(
        (res) => {
          this.motivationsData = res.data;
          console.log(this.motivationsData);
        },
        (error) => {
          this.errorMotivations = error.response.data.errors;
        }
      );
    },
    getMotivationCardsCampaigns() {
      this.$store.dispatch("motivations/getMotivationCardsCampaigns").then(
        (res) => {
          this.motivationsCompainData = res.data;
        },
        (error) => {
          this.errorMotivations = error.response.data.errors;
        }
      );
    },
    getAnalytics(params, sort) {
      let searchParam = `?page=${this.page}&n=20`;
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (this.searchData !== "") {
        searchParam = `?page=${this.page}` + this.searchData;
      }

      if (sort === undefined) {
        sort = "";
      }

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      params =
        searchParam +
        sort +
        this.filters +
        this.filtersDate +
        this.filtersLevel +
        this.filtersScills +
        `&start_date=${startDate}` +
        `&end_date=${endDate}`;

      this.$store.dispatch("analytics/skillsTracking", params).then(
        (res) => {
          this.campaignsHistoryData = res.data;
          this.analyticsMeta = res.meta;
        },
        (error) => {
          this.erroranalytics = error.response.data.errors;
        }
      );
    },
    setActiveTemplate(template: string) {
      if (template === "motivationalCards") {
        this.motivationalCardsTemplate = true;
        this.campaignsHistoryTemplate = false;
      } else if (template === "campaignsHistory") {
        this.motivationalCardsTemplate = false;
        this.campaignsHistoryTemplate = true;
      }
    },
    createMotivationalCards() {
      this.$router.push("/admin/create-motivational-card")
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    selectStaticRange(period) {
      this.period = period;
      this.getAnalytics();
    },
    handlePage(page) {
      this.page = page;
      // this.getInstructors();
    },
    goToDetailCard(id) {
      this.$router.push("/admin/motivational-card?motivation=" + id);
    },
  },
});
